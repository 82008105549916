@font-face {
    font-family: "Gilroy-black";
    src: url("../fonts/Gilroy-Black.eot");
    src: url('../fonts/Gilroy-Black.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Gilroy-Black.woff') format('woff'),
    url('../fonts/Gilroy-Black.ttf') format('truetype');
    font-display: swap;    
}

/* 
@font-face {
    font-family: "Gilroy-Bold";
    src: url("https://ql-web-dev.s3.us-east-2.amazonaws.com/fonts/gilroy.woff2") format('woff2');
    font-display: swap;
} */

@font-face {
    font-family: "Gilroy-Bold";
    src:  url('../fonts/gilroy.woff2') format('woff2');
    font-display: swap;
}

/* 
@font-face {
    font-family: "Gilroy-Bold";
    src: url("../fonts/Gilroy-Bold.eot");
    src: url('../fonts/Gilroy-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Gilroy-Bold.woff') format('woff'),
    url('../fonts/Gilroy-Bold.ttf') format('truetype');
    font-display: swap;
} */

@font-face {
    font-family: "Gilroy-Regular";
    src: url("../fonts/Gilroy-Regular.eot");
    src: url('../fonts/Gilroy-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Gilroy-Regular.woff') format('woff'),
    url('../fonts/Gilroy-Regular.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: "Gilroy-Medium";
    src: url("../fonts/Gilroy-Medium.ttf");
    font-display: swap;
}

@font-face {
    font-family: "Gilroy-SemiBold";
    src: url("../fonts/Gilroy-SemiBold.ttf");
    font-display: swap;
}

@font-face {
    font-family: "ObviouslyDemo-Wide";
    src: url("../fonts/ObviouslyDemo-Wide.otf");
    font-display: swap;
}

@font-face {
    font-family: "ClashDisplay-Variable";
    src: url("../fonts/ClashDisplay-Variable.ttf");
    font-display: swap;
}

.gilroy-medium , .Gilroy-medium , .Gilroy-Medium{
    font-family: "Gilroy-Medium"
}
.gilroy-bold , .Gilroy-bold , .Gilroy-Bold{
    font-family: "Gilroy-Bold"
}

.gilroy-semibold , .Gilroy-semibold , .Gilroy-semiBold{
    font-family: "Gilroy-SemiBold"
}
.gilroy-regular , .Gilroy-regular , .Gilroy-Regular{
    font-family: "Gilroy-Regular"
}
.gilroy-black , .Gilroy-black , .Gilroy-Black{
    font-family: "Gilroy-Black"
}

.ObviouslyDemo{ 
    font-family: "ObviouslyDemo-Wide";
}

.clash{
    font-family: "ClashDisplay-Variable";
}





.heading_1{font-size:48px;line-height:57.6px;font-family: "Gilroy-black";}
.heading_2{font-size: 40px;line-height:48px;font-family: "Gilroy-semiBold";}
.heading_3{font-size: 24px;line-height:28.8px;font-family: "Gilroy-semiBold";}
.heading_4{font-size: 20px;line-height: 24px;font-family: "Gilroy-Bold";}


/* Karan Style Start Here */


.accordion_faq ul li{font-family: "Gilroy-Regular";}

.heading_5{font-size: 32px!important;line-height:48px;font-family: "Gilroy-Bold";}
.heading_K_2{font-size: 40px;line-height:48px;font-family: "Gilroy-Bold";}
.heading_K_4{font-size: 20px;line-height: 24px;font-family: "Gilroy-Bold";}


/* New Portfolio Page Design Start */


:root{
    --portfolio_new_dark:#202026;
    --portfolio_new_mute:#6D6D6D;
    --portfolio_new_grey:#777777;
    --portfolio_new_voilet:#2942A7;
    --portfolio_new_border:#718096;
    --portfolio_new_light_grey:#F3F3F3;
    --portfolio_new_light_dark:#232323;
    --portfolio_new_voilet_dark:#2842A9;
}

.fs-22{font-size: 22px;}

.theme_new_voilet{color: var(--portfolio_new_voilet);}
.newgrey_border{border-color: var(--portfolio_new_border);;}
.theme_new_light_dark{color: var(--portfolio_new_light_dark);}
.theme_new_dark{color: var(--portfolio_new_dark);}
.theme_new_grey{color: var(--portfolio_new_grey);}
.theme_new_muted{color: var(--portfolio_new_mute);}
.theme_new_voilet_dark{background: var(--portfolio_new_voilet_dark);}


/* Gradient Homepage Design Start */


.heading_new_homepage{font-size: 45px;line-height:62px;}

/* Gradient Homepage Design Finish */




.heading_new_K_1{font-size: 42px;line-height:48px;font-family: "Gilroy-Bold";}
.heading_new_K_3{font-size: 35px;line-height:48px;font-family: "Gilroy-SemiBold";}
.heading_new_K_5{font-size: 22px;line-height:32px;font-family: "Gilroy-SemiBold";}



    .new_gradient-with-lernloft {
        background: url(../../src/images/new_bg_layer.png) 106% -65px, url(../../src/images/new_bg_layer.png)-8% 83px,linear-gradient(#2842a9d6 0.17%, #2842A9 99.8%);
        background-repeat: no-repeat, no-repeat;
     
    }

    .new_gradient-with-rubarb {
        background: url(https://ql-web-images.s3.us-east-2.amazonaws.com/ql-images/RhubarbGradient.png);
        background-repeat: no-repeat, no-repeat;
        background-size: cover;
    }
    

    .impact_sec{
        background:url(../../src/images/learnloft_impact_bg.png)no-repeat center center / cover ;
    }

    .icons_bg{
        background:url(../../src/images/design_bg.svg)no-repeat center center / cover ;
    }




    .user-review{
        background: var(--portfolio_new_light_grey);
    }

    .user-review::after{
        position: absolute;
        content: '';
        width: 0;
        height: 0;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-top: 19px solid #f7f7f7;
        bottom: -19px;
        left: 21px;
    }
    

    .icons_shadow{box-shadow: 0px 20px 20px 0px #2842a959;}
    

    .speedy_title{font-size: 24px;}
    .speedy_sub_title{font-size: 35px;}
    
    

/* New Portfolio Page Design Finish */



.gradient-with-image {background: linear-gradient(to right, #1D509E, #0D3676),url(../../src/images/pepsico_bg_layer.svg); background-blend-mode: overlay; background-size: cover; background-position: 50% 70%;}



.gradient-with-shl {background: linear-gradient(to right, #7EBB4B, #549320),url(../../src/images/pepsico_bg_layer.svg); background-blend-mode: overlay; background-size: cover; background-position: 50% 70%;}
.gradient-with-plately {background: linear-gradient(to right, #BD3565, #A32552),url(../../src/images/pepsico_bg_layer.svg); background-blend-mode: overlay; background-size: cover; background-position: 50% 70%;}
.gradient-with-clinic {background: linear-gradient(to right, #0166E5, #2825BF),url(../../src/images/pepsico_bg_layer.svg); background-blend-mode: overlay; background-size: cover; background-position: 50% 70%;}
.gradient-with-myysports {background: linear-gradient(to right, #543FD6, #2825BF),url(../../src/images/pepsico_bg_layer.svg); background-blend-mode: overlay; background-size: cover; background-position: 50% 70%;}
.gradient-with-quixy {background: linear-gradient(to right, #0565FF, #0565FF),url(../../src/images/pepsico_bg_layer.svg); background-blend-mode: overlay; background-size: cover; background-position: 50% 70%;}
.gradient-with-jaisiri {background: linear-gradient(to right, #54E6B6, #33C192, #139C6F),url(../../src/images/pepsico_bg_layer.svg); background-blend-mode: overlay; background-size: cover; background-position: 50% 70%;}


/* background: linear-gradient(92.34deg, #54E6B6 -4%, #33C192 33.27%, #139C6F 100.5%),
linear-gradient(0deg, #E2E8F0, #E2E8F0); */



.text-K-outline {-webkit-text-fill-color: transparent; -webkit-text-stroke-color: #4F4F4F; -webkit-text-stroke-width: .012em;}
.myysports_last_banner_sec .text-K-outline {-webkit-text-fill-color: transparent; -webkit-text-stroke-color: #ffffff; -webkit-text-stroke-width: .012em;}

/* background: linear-gradient(90deg, #0166E5 0%, #2825BF 100%),
linear-gradient(0deg, #E2E8F0, #E2E8F0); */


.free_counsult_bg{background: #F9F9FF;}

/* .text_effect{font-size: 42px !important;} */

.clinic_last_banner_sec{background: url(../images/what_impact_clinic_large.webp) , linear-gradient(to right, #0a1222, #0a1222);
    background-repeat: no-repeat, no-repeat;
    background-size: 50%,100%;
    background-position: bottom right;
 }

 
.myysports_last_banner_sec{background: url(../images/myysports_what_impact_web.webp) , linear-gradient(to right, #141b2b, #141b2b);
    background-repeat: no-repeat, no-repeat;
    background-size: 200%,100%;
    background-position: 98% -3%;
 }

 .quixy_watermarkText{font-size: 152px; font-family: 'Gilroy-bold';}


 .box-quixy{background:linear-gradient(90deg, #ffffff 68%, #ffffff 68%);  background-size: 406px; background-repeat: no-repeat; background-position: right top; }

 .result_bg {background: linear-gradient(to right, #fff, #fff),url(../../src/images/quixy_result_bg.webp); background-blend-mode: overlay; background-size: cover; background-position: 2% 70%;}
 /* .result_bg {background: linear-gradient(to right, #ebe7ee, #FAF7FC),url(../../src/images/quixy_result_bg.png); background-blend-mode: overlay; background-size: cover; background-position: 2% 70%;} */


 .gradient-btn{
    color: #fff;
    background: linear-gradient(91.17deg, #2842a9d6 0.17%, #2842A9 99.8%)
 }
 

 /* .newsCard img{
    border-radius: 32px;
 } */



/* .pepsi_bg{background: url(../../src/images/coke_bg.svg) no-repeat 8% 50%;} */
/* .speedy_bg{background: url(../../src/images/speedy_bg.svg) no-repeat center center / cover;} */
/* background: linear-gradient(93deg, #7EBB4B 22.95%, #549320 86.46%),linear-gradient(0deg, #E2E8F0, #E2E8F0); */


/* Karan Style Finish Here */


.body_1{font-size:20px;line-height:30px;font-family: "Gilroy-Regular";}
.body_2{font-size:16px;line-height:24px;font-family: "Gilroy-Regular";}
.sub_heading_1{font-size: 18px;line-height: 26px;font-family: "Gilroy-Medium";}
.sub_heading_2{font-size: 17px;line-height: 23px;}
.sub_heading_3{font-size: 16px;line-height: 23px;font-family: "Gilroy-Medium";}
.Gilroy-Regular{font-family: "Gilroy-Regular";}
/* .Gilroy-Bold{font-family: "Gilroy-Bold";} */
.Gilroy-semiBold{font-family: "Gilroy-semiBold";}
/* .Gilroy-Medium{font-family: "Gilroy-Medium";} */
.text-color-black,.text-color-gray {color:#050505;}
.text-color-body{color: #4F4F50;}
.gradiantText{background: linear-gradient(90deg, #5C3EB3 0%, #C142C3 105.44%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;}
.headingFive{font-size: 20px;line-height:30px;font-family: "Gilroy-semiBold";color:#050505;}
.miniPara{font-size:14px;line-height:20px;font-family: "Gilroy-Regular";}
.footerLinks{font-size:13px;line-height:30px;font-family: "Gilroy-semiBold";color:#050505;opacity: 0.8;}
.footerLinks a:hover{text-underline-offset: 8px;text-decoration: underline;color:#5C3EB3;}
.borderB{border-bottom: 1px solid #5C3EB3;}

/* for story Time Line */
.wrapper{position: relative;}
.wrapper .center-line{position: absolute;height: 98%;width: 4px;background: #5C3EB3;left: 50%;top: 20px;
transform: translateX(-50%);}
.wrapper .row{display: flex;}
.wrapper .row-1{justify-content: flex-start;}
.wrapper .row-2{justify-content: flex-end;}
.wrapper .row section{border-radius: 5px;width: calc(50% - 40px);padding-top: 1rem;position: relative;}
.row section .icon,
.center-line .scroll-icon{position: absolute;background: #C6B6F5;height: 25px;width: 25px;text-align: center;
border-radius: 50%;display: grid;align-items: center;justify-content: center;}
.row-1 section .icon{top: 15px;right: -52px;}
.row-1 section{text-align: right;}
.row-2 section .icon{top: 15px;left: -52px;}
.small_circle{height: 15px;border-radius: 50%;background-color: #5C3EB3;width: 15px;}


.tech-n-20{
    top: 0px
}


/* for mobile */
@media (max-width:768px) {
    /* .heading_1{font-size:30px;line-height:36px;} */
    .heading_1{font-size:32px;line-height:36px;}
    .heading_2{font-size: 24px;line-height:28.8px;}
    .heading_3{font-size: 18px;line-height: 24px;}
    .heading_4{font-size: 14px;line-height: 23.4px;}

    /* Karan Style Start */


    h1.heading_K_2{font-size: 32px;line-height:45px;font-family: "Gilroy-Bold";}
    
    .heading_K_2{font-size: 20px;line-height:38px;font-family: "Gilroy-Bold";}
    .heading_K_4{font-size: 16px;line-height: 23.4px;font-family: "Gilroy-Bold";}
    .heading_5{font-size: 24px!important;line-height:31px;font-family: "Gilroy-Bold";}
    .coke_data .heading_5{font-size: 20px !important; }



    .plately_last_banner_sec {
        /* background: #0A1222,url('../../src/images/plately_impact_img.webp') no-repeat bottom right / 25%; */
        background-image:  url('../../src/images/what_impact_plately.webp'),linear-gradient(90deg, #0a1222 100%,  #0a1222 100%);
        background-repeat: no-repeat;
        background-position: right bottom;
        background-size: 100% , 100%;
        /* background-color: linear-gradient(#0A1222,#0A1222),url('../../src/images/plately_impact_img.webp') no-repeat bottom right / 25%; */
    }
 
    .tech-n-20{
        top: -30vw   
    }

    #parant_relative{
        margin-top: 5%;
    }
    

    
    

    
/* .plately_last_banner_sec {
    background: url('../../src/images/what_impact_small.png') no-repeat 11% 7%/ 54%, #F7F6F9;
} */



    
    
    /* .shl_second_banner{background:none; } */

    
    /* Karan Style Finish */
    
    
    .body_1{font-size: 16px;line-height: 24px;}
    .body_2{font-size: 14px;line-height: 24px;}
    /* .sub_heading_1{font-size: 22px;line-height: 27.2px;} */
    .sub_heading_1{font-size: 18px;line-height: 23.4px;}
    .sub_heading_2{font-size: 16px;line-height: 20.8px;}
    .sub_heading_3{font-size: 14px;line-height: 18.2px;}
    .wrapper .center-line{left: -30px;}
    .wrapper .row{margin: 30px 0 3px 60px;}
    .wrapper .row section{width: 100%;}
    .row-1 section::before{left: -7px;}
    .row-1 section .icon{left: -43px;}
    .row-2 section .icon{top: 15px;left: -43px;}
    .row section .icon,
    .center-line .scroll-icon{height: 25px;width: 25px;}
    .row-1 section{text-align: left;}
    .wrapper .row{margin: 10px 0;}
    }



    
    
/* Create By Karan Style Start */






/* Karan Thank You Page Start */


/* .thanks_card .card_number::after{
    content: '';
    position: absolute;
    right: -40px;
    height: 2px;
    top: 20px;
    width: 99%;
    background: #ff7575;  
  } */
.thanks_card::before{
    content: '';
    position: absolute;
    right: -58.2%;
    height: 2px;
    top: 20px;
    width: 99%;
    background: #ff7575;
  }
  
  .thanks_card:last-child::before{
    display: none;
  }
  
  
@media (max-width:1023px) {


/* .thanks_card .card_number::after{
    content: '';
    position: absolute;
    left: 21px;
    height: 99%;
    top: 42px;
    width: 2px;
    background: #ff7575;
  } */
    .thanks_card::before{display: none;}
    .thanks_card::after{
    content: '';
    position: absolute;
    left: 21px;
    height: 99%;
    top: 42px;
    width: 2px;
    background: #ff7575;
  }

  .tech-n-20{
    top: -15vw
}

    
}
  /* Karan Thank You Page Finish */
  

@media (min-width:1025px) and (max-width: 1279px){
    .thanks_card::before{
        content: '';
        position: absolute;
        right: -56.8%;
        height: 2px;
        top: 20px;
        width: 99%;
        background: #ff7575;
      }
}  

@media (min-width:1280px) and (max-width: 1440px){
    .thanks_card::before{
        content: '';
        position: absolute;
        right: -55.8%;
        height: 2px;
        top: 20px;
        width: 99%;
        background: #ff7575;
      }
}
  

@media (min-width:1441px) {
        .thanks_card::before{
        content: '';
        position: absolute;
        right: -52.8%;
        height: 2px;
        top: 20px;
        width: 99%;
        background: #ff7575;
      }

}

    
    
/* for tablet and large screens */


/* Karan New Portfolio Page Design Media Query Start */



@media (min-width:319px) and (max-width: 639px){
    .heading_new_K_1{
        font-size: 28px;
        line-height:38px ;
    }

        

    .speedy_sub_title{font-size: 20px;}
    


    .new_gradient-with-lernloft {
        background: url(../../src/images/new_bg_layer.png) 134% -65px, url(../../src/images/new_bg_layer.png)-30% 206px,linear-gradient(#2842a9d6 0.17%, #2842A9 99.8%);
        background-repeat: no-repeat, no-repeat;
    }
    
    
    .heading_new_K_3{font-size: 24px;line-height:32px;font-family: "Gilroy-SemiBold";}
    

    
    .fs-22{font-size: 18px;}

    .heading_new_homepage {
        font-size: 24px;
        line-height: 33.6px;
    }


    .android_heading{
        font-size: 24px;
        line-height: 33.6px;
    }  
        
        .tech-n-20{
            top: -40vw   
        }

        #parant_relative{
            margin-top: 25%;
        }


        .headingFive {
            font-size: 14px;
        }
        
        

        
} 

/* Karan New Portfolio Page Design Media Query Finish */





@media (min-width:768px) {
.AMCAT_test{background: url(../../src/images/shl_mid_second_banner.webp) no-repeat center center / cover;}    


.shl_second_banner{background: url(../images/shl_mid_without_content_banner.webp) no-repeat center center / cover; }
.shl_second_banner .heading_K_4{line-height: 26px;}


/* .plately_last_banner_sec {
    background: url('../../src/images/plately_impact_img.webp') no-repeat bottom right / 25%, #F7F6F9;
} */


.plately_last_banner_sec {
    /* background: #0A1222,url('../../src/images/plately_impact_img.webp') no-repeat bottom right / 25%; */
    background-image:  url('../../src/images/what_impact_plately_web.webp'),linear-gradient(90deg, #0a1222 100%,  #0a1222 100%);
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: 80% , 100%;
    /* background-color: linear-gradient(#0A1222,#0A1222),url('../../src/images/plately_impact_img.webp') no-repeat bottom right / 25%; */
}


.myysports_last_banner_sec{background: url(../images/myysports_what_impact_web.webp), linear-gradient(to right, #141b2b, #141b2b);;
    background-repeat: no-repeat, no-repeat;
    background-size: 160%,100%;
    background-position: 98% 11%;
 }


 /* .myysports_last_banner_sec{background: url(../images/myysports_what_impact_web.webp) , linear-gradient(to right, #141b2b, #141b2b);
    background-repeat: no-repeat, no-repeat;
    background-size: 200%,100%;
    background-position: 98% 6%;
 
  */
 
 
/* .myysports_last_banner_sec{background: url(../images/myysport_what_impact.webp) , linear-gradient(to right, #F6B306, #F6B306);
    background-repeat: no-repeat, no-repeat;
    background-size: 15%,100%;
    background-position: top right;
 } */




}
    


    
/* Create By Karan Style Finish */
    
    